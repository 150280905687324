<template>
  <div class="admin-form-forms" :class="{skeleton: !state.loaded}">
    <div class="header">
      <div class="left">
        <span>총 {{ state.total || 0 }}건</span>
      </div>
      <label class="right" :for="`${component.name}Search`">
        <input type="text" class="form-control form-sm" v-model="state.args.keyword" @keyup.enter="search()" placeholder="양식 이름을 입력해주세요">
        <span class="reset pointer" @click="search(true)" v-if="$route.query.keyword">&times;</span>
        <button class="btn" @click="search()">
          <i class="fa fa-search"></i>
        </button>
      </label>
    </div>
    <div class="table-responsive" v-if="!state.loaded || state.forms.length">
      <table class="table">
        <thead>
        <tr>
          <th>
            <span>번호</span>
          </th>
          <th>
            <span>양식 이름</span>
          </th>
          <th>
            <span>신청서 URL</span>
          </th>
          <th>
            <span>마감일</span>
          </th>
          <th>
            <span>단계 편집</span>
          </th>
          <th>
            <span>항목 편집</span>
          </th>
          <th>
            <span>제약 편집</span>
          </th>
          <th>
            <span>복제</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(f, idx) in state.forms" :key="idx">
          <td>
            <span>{{ state.total - state.args.page * state.args.size - idx }}</span>
          </td>
          <td>
            <span class="pointer-u" @click="edit(f, 'info')">{{ f.title }}</span>
          </td>
          <td>
            <template v-if="f.constraintFlag === 'Y'">
              <span class="pointer-u" @click="$store.commit('openModal', { name: 'FormUrls', params: { formId: f.id, formUrl: getUrl(f.name) }})">URL 보기</span>
              <i class="ml-2 help fa fa-question-circle-o" title="도움말" data-text="신청서에 제약이 있는 경우, n개의 URL이 존재합니다."></i>
            </template>
            <template v-else>
              <a class="pointer-u" :href="getUrl(f.name)" target="_blank" rel="noopener noreferrer" title="해당 신청서로 이동">{{ getUrl(f.name) }}</a>
              <span class="pointer ml-2 badge badge-primary" @click="copy(getUrl(f.name))">복사</span>
            </template>
          </td>
          <td>
            <span>{{ f.expiryDate ? f.expiryDate : "(N/A)" }}</span>
          </td>
          <td>
            <a class="pointer-u color-purple" @click="edit(f, 'step')">단계 편집</a>
          </td>
          <td>
            <a class="pointer-u color-purple" @click="edit(f, 'item')">항목 편집</a>
          </td>
          <td>
            <a class="pointer-u color-purple" @click="edit(f, 'constraint')" v-if="f.constraintFlag === 'Y'">제약 편집</a>
          </td>
          <td>
            <a class="pointer-u color-purple" @click="clone(f)">복제</a>
          </td>
        </tr>
        </tbody>
      </table>
      <PaginationJpa :pageable="state.pageable" :total="state.total" :change="load" className="btn-primary" v-if="state.total"/>
    </div>
    <NoData v-else/>
    <a class="add" @click="add()">
      <i class="fa fa-plus"></i>
    </a>
  </div>
</template>

<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import store from "@/scripts/store";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import NoData from "@/components/NoData";
import PaginationJpa from "@/components/PaginationJpa";
import Number from "@/components/Number.vue";

function Component(initialize) {
  this.name = "pageAdminFormForms";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, PaginationJpa, NoData, Wait},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        page: 0,
        size: 10,
        keyword: "",
      },
      pageable: {
        page: 0,
        size: 0,
      },
      total: 0,
      forms: []
    });

    const urlPrefix = [location.origin, "/forms/"].join("");

    const load = (page) => {
      (page !== undefined && page !== null && page !== window.Number(router.app.$route.query.page)) && router.push({
        query: {
          ...router.app.$route.query,
          page,
        }
      });

      state.total = 0;
      state.args.page = window.Number(router.app.$route.query.page) || 0;
      state.args.keyword = router.app.$route.query.keyword || "";
      state.loaded = false;

      for (let i = 0; i < 5; i += 1) {
        state.forms.push({
          description: "Please wait a moment",
          name: "Wait a moment",
          title: "Please wait a moment"
        });
      }

      http.get("/api/admin/forms", state.args).then(({data}) => {
        state.loaded = true;
        state.forms = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;
      });
    };

    const getUrl = (name) => {
      return urlPrefix + name;
    };

    const add = () => {
      store.commit("openModal", {
        name: "FormInfo",
        params: {
          type: "add",
        },
        callback: `${component.name}.load`
      });
    };

    const edit = (form, name) => {
      switch (name) {
        case "info":
          store.commit("openModal", {
            name: "FormInfo",
            params: {
              form,
              type: "edit",
            },
            callback: `${component.name}.load`
          });
          break;

        case "constraint":
          store.commit("openModal", {
            name: "FormConstraints",
            params: {
              formId: form.id,
              constraintParentLabel: form.constraintParentLabel,
              constraintChildLabel: form.constraintChildLabel,
            },
            callback: `${component.name}.load`
          });
          break;

        case "step":
          store.commit("openModal", {
            name: "FormSteps",
            params: {
              formId: form.id,
            }
          });
          break;

        case "item":
          store.commit("openModal", {
            name: "FormItems",
            params: {
              keyItemId: form.keyItemId,
              formId: form.id,
            },
            callback: `${component.name}.load`
          });
          break;
      }
    };

    const copy = (value) => {
      lib.copyToClipboard(value);
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const clone = (f) => {
      const title = window.prompt("새로운 양식의 이름을 입력해주세요.", f.title);

      if (!title?.trim()) {
        return;
      } else if (f.title === title) {
        return store.commit("setSwingMessage", "기존의 양식 이름과 다른 이름을 입력해주세요.");
      }

      http.post(`/api/admin/forms/${f.id}/clone`, {title}).then(() => {
        store.commit("setSwingMessage", "신청서 양식 복제를 완료하였습니다.");
        store.commit("refresh");
      });
    };

    const search = (remove) => {
      const query = lib.getRenewed(router.app.$route.query);

      if (remove) {
        delete query.keyword;
      } else {
        query.keyword = state.args.keyword?.trim();
      }

      query.page = 0;
      router.push({query});
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (JSON.stringify(next) === JSON.stringify(prev) || (next.modals || prev.modals)) {
        return;
      }

      load(null, true);
    });

    return {component, state, load, getUrl, add, edit, copy, clone, search,};
  }
});
</script>

<style lang="scss" scoped>
.admin-form-forms {
  padding-bottom: $px25;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: $px1 solid $colorBorder;
      border-radius: $px4;
      transition: border 0.18s;

      .form-control {
        font-size: $px12;
        border: 0;
      }

      .reset {
        padding: $px12;
      }

      .btn {
        font-size: $px12;
        border: 0;
        background: $colorBackground;
        height: $formHeight;
      }

      &:focus-within {
        border: $px1 solid $colorPurple;
      }
    }
  }

  .table-responsive {
    table {
      tr {
        th, td {
          padding-left: $px10;
          padding-right: $px10;
        }

        th {
          background: $colorBackground;
          border-bottom: 0;
        }
      }
    }
  }

  .no-data {
    padding: $px50 0;
  }

  .pagination {
    margin-top: $px25;
  }

  .add {
    background: $colorPurple;
    bottom: $px30;
    border-radius: 50%;
    display: inline-block;
    height: $px60;
    padding: 0;
    left: 100%;
    width: $px60;
    cursor: pointer;
    text-align: center;
    position: sticky;
    transition: background-color 0.18s;
    z-index: 110;

    .fa-plus {
      color: #fff;
      font-size: $px22;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background: $colorPurpleActive;
    }
  }

  &.skeleton {
    .table-responsive {
      table tr {
        th, td {
          span, a {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>